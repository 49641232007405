<script setup lang="ts">
</script>
<template>
  <div
    class="tools"
    id="tools"
  >
    <div class="tools-container">
      <div class="tools-content">
        <h2 class="tools-content-title">ИНСТРУМЕНТЫ</h2>
        <div class="tools-content-plates">
          <div class="tools-content-plates-plate">
            <img
              class="tools-content-plates-plate-icon"
              src="@/assets/images/svg/ad.svg"
              alt="">
            <h3 class="tools-content-plates-plate-title">
              Высокоэффективная Реклама, Интересная Пользователям
            </h3>
            <p class="tools-content-plates-plate-description">
              Мы показываем таргетированную рекламу на основе наших данных о пользователях -
              демография, интересы, поведенческие и социальные характеристики итд.
              <br><br>
              Реклама транслируется наиболее релевантной аудитории, что обеспечивает отличные
              результаты для рекламодателей и более высокий доход для издателей.
            </p>
          </div>
          <div class="tools-content-plates-plate">
            <img
              class="tools-content-plates-plate-icon"
              src="@/assets/images/svg/file-chart-line.svg"
              alt="">
            <h3 class="tools-content-plates-plate-title">
              Доступны Все Популярные Рекламные Форматы
            </h3>
            <p class="tools-content-plates-plate-description">
              Для приложений - полностью настраиваемая нативная статичная реклама и нативное
              видео, Rewarded Video (Видео за награду), In-Stream видео, баннеры и
              полноэкранная реклама.
              <br><br>
              Для сайтов - стандартные рекламные блоки и In-Stream видео
            </p>
          </div>
          <div class="tools-content-plates-plate">
            <img
              class="tools-content-plates-plate-icon"
              src="@/assets/images/svg/comment-user.svg"
              alt="">
            <h3 class="tools-content-plates-plate-title">
              Эксклюзивные Брендовые Рекламодатели
            </h3>
            <p class="tools-content-plates-plate-description">
              Помимо perfomance рекламодателей, вы получите доступ к
              премиальной рекламе от всемирно известных брендов.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tools-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: get-vw(1050px);
  margin-left: auto;
  margin-right: auto;
}
.tools {
  width: get-vw(1920px);
  margin-left: get-vw(-385px);
  margin-right: auto;
  margin-top: get-vw(130px);
  background: linear-gradient(180deg, #4C4EF9 55%, #FFFFFF 45%);
  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: get-vw(70px);
    &-title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: get-vw(45px);
      color: #FFFFFF;
    }
    &-plates {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: get-vw(60px);
      &-plate {
        width: get-vw(200px);
        height: auto;
        padding: get-vw(30px);
        background: #FFFFFF;
        border-radius: 0 get-vw(60px) 0 0;
        &-icon {
          width: get-vw(60px);
          height: auto;
          margin-top: get-vw(30px);
        }
        &-title {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 700;
          font-size: get-vw(16px);
          line-height: 130%;
          color: #000000;
          margin-top: get-vw(40px);
        }
        &-description {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: get-vw(14px);
          line-height: 140%;
          color: #000000;
          margin-top: get-vw(40px);
        }
      }
    }
  }
}
</style>
